import React from 'react'
import { GlobalStyles, Theme } from '@mui/material'

export const inputGlobalStyles = (theme: Theme) => (
  <GlobalStyles
    styles={{
      html: { overflowY: 'scroll', scrollBehavior: 'auto' },
      body: {
        boxSizing: 'border-box',
        fontFamily: theme.typography.fontFamily,
        minHeight: '100vh',
        background: `linear-gradient(180deg, ${theme.palette.primary.dark} 380px, ${theme.colours.new.foreground}  0%)`,
        backgroundRepeat: 'no-repeat',
      },
      main: { marginBottom: '50px' },
      img: {
        maxWidth: '100%',
        height: 'auto',
      },
      input: {
        fontFamily: theme.typography.fontFamily,
      },
      label: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      ['.connectorLine']: {
        borderColor: 'white',
        borderWidth: '1px',
      },
      ['.connectorLineDisabled span']: {
        borderColor: theme.palette.primary.light,
      },
      a: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
      ['a:hover']: {
        textDecoration: 'underline',
      },
      ['.custom-checkbox.checked span']: {
        backgroundColor: `${theme.palette.primary.main}`,
        borderColor: `${theme.palette.primary.main}`,
      },
      ['.custom-checkbox.checked span:hover']: {
        borderColor: `${theme.palette.primary.main}`,
      },
      ['ol, ul']: {
        listStyle: 'none',
      },
      ['body, h1, h2, h3, h4, h5, h6, p, ol, ul']: {
        margin: 0,
        padding: 0,
      },
      button: {
        fontFamily: theme.typography.fontFamily,
        background: 'transparent',
        cursor: 'pointer',
        border: 'none',
      },
      ['legend']: {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      },
    }}
  />
)
